import React, { useEffect, useState } from 'react';

const WeekOnWeekTable = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://cruncher.asset.money/artist/user-count-week-on-week')
            .then(response => response.json())
            .then(data => {
                setData(data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    let weekStart = 34

    if (isLoading) return <p className="text-center">Loading...</p>;
    if (error) return <p className="text-center text-red-500">Error: {error}</p>;

    const filteredData = data?.reverse()?.filter(week => week.active_users > 0);

    return (
        <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">This month weekly quizzes</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-6 py-3">Week</th>
                            <th className="px-6 py-3">Badges Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((week, index) => (
                            <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                <td className="px-6 py-4 font-medium text-gray-900">Week {weekStart + (filteredData.length - index)}</td>
                                <td className="px-6 py-4">{week?.active_users.toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WeekOnWeekTable;