import React, { useState, useEffect } from 'react';

const WalletStats = () => {
    const [totalWallets, setTotalWallets] = useState(null);
    const [walletsToday, setWalletsToday] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch total wallets
        fetch('https://cruncher.asset.money/user/total-solwallets')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTotalWallets(data.data[0].total_wallets);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching total wallets:', error);
                setError(error.message);
                setIsLoading(false);
            });

        // Fetch wallets created today
        fetch('https://cruncher.asset.money/user/total-solwallets-today')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data.data[0].count);
                setWalletsToday(data.data[0].count);
            })
            .catch(error => {
                console.error('Error fetching today\'s wallets:', error);
                // We don't set isLoading here as we already handle it in the first fetch
            });
    }, []);

    return (
        <div className='flex flex-col justify-center items-center gap-4 w-full'>
            <div className="card w-full">
                <h2 className="text-xl font-bold mb-4">Total Wallets Created</h2>
                {isLoading ? (
                    <div className="number loading">Loading...</div>
                ) : error ? (
                    <div className="number error">Error: {error}</div>
                ) : (
                    <div className="number">{totalWallets?.toLocaleString()}</div>
                )}
            </div>
            <div className="card w-full">
                <h2 className="text-xl font-bold mb-4">Wallets Created Today</h2>
                {isLoading ? (
                    <div className="number loading">Loading...</div>
                ) : error ? (
                    <div className="number error">Error: {error}</div>
                ) : (
                    <div className="number">{walletsToday?.toLocaleString()}</div>
                )}
            </div>
        </div>
    );
};

export default WalletStats; 