import React, { useState, useEffect } from 'react';

const ArtistsDiscovered = () => {
    const [artistsDiscovered, setArtistsDiscovered] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://cruncher.asset.money/artist/discovered-count')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setArtistsDiscovered(data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="card">
            <h2 className="text-xl font-bold mb-4">Artists Discovered</h2>
            {isLoading ? (
                <div className="number loading">Loading...</div>
            ) : error ? (
                <div className="number error">Error: {error}</div>
            ) : (
                <div className="number">{artistsDiscovered?.toLocaleString()}</div>
            )}
        </div>
    );
};

export default ArtistsDiscovered;
